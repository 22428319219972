import React, { useEffect } from 'react';
import {Link} from 'react-router-dom'
import './Intro.css'
import $ from 'jquery';


const Intro = () => {
  
	useEffect(() => {
		// jQuery document ready equivalent
		$(document).ready(function() {
		  $('.menu-toggle-btn').click(function() {
			$('.menu-toggle-btn').toggleClass("effect");
			$('.menu-list').toggleClass("effect");
		  });
		});
	
		$(document).on('click', '.accor > .head', function() {
		  var closestDiv = $(this).closest('.accor');
		  closestDiv.find('.body').slideToggle();
		});
	
		   // Trigger animations on mount
		   setTimeout(() => {
			$('.logo-container').addClass('fade-in delay-1');
			$('.welcome-text').addClass('fade-in delay-2');
			$('.luck-text').addClass('fade-in delay-3');
			$('.enter').addClass('fade-in delay-4');
		  }, 1000);
	
		// Cleanup function to remove the event listeners when the component is unmounted
		return () => {
		  $(document).off('click', '.accor > .head');
		  $('.menu-toggle-btn').off('click');
		};
	  }, []);

      return(
      <>
	  
		<div className="d-flex flex-column flex-root" style={{'backgroundColor': 'rgb(220 232 213)'}}>
			<div className=" mb-5 mb-xl-10">
				
				<div className=" mb-5 mb-xl-10" >
					
					<div className=" mb-5 mb-xl-10">
						
						<div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
							
							<a className='logo-container' href="#" >
								<img alt="Logo" src="assets/media/logos/logo-2.png" className="h-100px" />
							</a>
							
							<div className="parent-container">
							<div className="menu-container">
  <div className="menu-toggle-btn">
    <i className="fa fa-language"></i>
  </div>
  <ul className="menu-list">
    <li className="menu-item">
      <a href="#"><strong>عربي</strong></a>
    </li>
	<li className="menu-item">
      <a href="#"><strong>EN</strong></a>
    </li>
    <li className="menu-item">
      <a href="#"><strong>FR</strong></a>
    </li>
  </ul>
</div>
							</div>

							<h1 className="fw-bolder fs-2qx pb-5 welcome-text" style={{'color': '#000'}}>Welcome to Go Test</h1>
							
							<p className="fw-bold fs-2 luck-text" style={{'color': 'rgb(240 12 72)'}}>We wish you good luck 
							</p>
						</div>
					
						

						<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px" style={{'backgroundImage': 'url(../../../assets/media/illustrations/sketchy-1/13.png'}}></div>
						<Link className='enter' to="/login">Enter</Link>

					</div>
					
				</div>
				
			</div>
			
		</div>
        </>
      )
    }



    export default Intro