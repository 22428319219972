const Login = () => 
    {
      return(
      <>
		<div className="d-flex flex-column flex-root">
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				
				<div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style={{'backgroundColor': 'rgb(178 222 252)'}}>
					
					<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
						
						<div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
							
							<a href="../../demo6/dist/index.html" >
								<img alt="Logo" src="assets/media/logos/logo-2.png" className="h-100px" />
							</a>
							
							<h1 className="fw-bolder fs-2qx pb-5 " style={{'color': '#000'}}>Welcome to Go Test</h1>
							
							<p className="fw-bold fs-2" style={{'color': 'rgb(240 12 72)'}}>We wish you good luck 
							</p>
						</div>
						
						<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px" style={{'backgroundImage': 'url(../../../assets/media/illustrations/sketchy-1/13.png'}}></div>
						
					</div>
					
				</div>
				
				<div className="d-flex flex-column flex-lg-row-fluid py-10" style={{'backgroundColor': '#FFFF'}}>
					<div className="d-flex flex-center flex-column flex-column-fluid">
						<div className="w-lg-500px p-10 p-lg-15 mx-auto">
							<form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" action="#">
								<div className="text-center mb-10">
									<h1 className="text-dark mb-3">Sign In to Go Test</h1>
									
									<div className="text-gray-400 fw-bold fs-4">New Here? 
									<a href="../../demo6/dist/authentication/flows/aside/sign-up.html" className="link-primary fw-bolder">  Create an Account</a></div>
									
								</div>
								
								<div className="fv-row mb-10">
								
									<label className="form-label fs-6 fw-bolder text-dark">Email</label>
									
									<input className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" />
								
								</div>
							
								<div className="fv-row mb-10">
									
									<div className="d-flex flex-stack mb-2">
										
										<label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
										
										<a href="../../demo6/dist/authentication/flows/aside/password-reset.html" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>
										
									</div>
									
									<input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" />
									
								</div>
								
								<div className="text-center">
									
									<button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
										<span className="indicator-label">Continue</span>
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
									
								</div>
								
							</form>
							
						</div>
					
					</div>
					
					<div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
						
						<div className="d-flex flex-center fw-bold fs-6">
							<a href="#" className="text-muted text-hover-primary px-2" rel="noreferrer" target="_blank">About</a>
							<a href="#" className="text-muted text-hover-primary px-2" rel="noreferrer" target="_blank">Support</a>
							<a href="#" className="text-muted text-hover-primary px-2" rel="noreferrer" target="_blank">Purchase</a>
						</div>
					
					</div>
					
				</div>
				
			</div>
			
		</div>
        </>
      )
    }



    export default Login