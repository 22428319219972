import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Intro from '../src/Intro/Intro.js'
import Login from '../src/Authentication/Login/login.js'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Intro/>}/>
      <Route path="/login" element={<Login/>}/>
    </Routes>
  </Router>
);


